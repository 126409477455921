<template>
    <div class="page-cosmetics">
        <banner-carousel></banner-carousel>
        <div class="container">
            <!-- 化妆品入口 -->
            <router-link to="/equipment"
                class="cosmetics-to_equipment">
                摄影器材专区入口
                <i class="el-icon-d-arrow-right"></i>
            </router-link>
            <!-- 搜索框 -->
            <main-filter>
            </main-filter>
            <!-- 热门 -->
            <main-hot
                hot-title="热销产品"
                list-router="/cosmetics/list">
                <template #goodsItem>
                    <goods-item
                        router-path="/cosmetics/detail">
                    </goods-item>
                </template>
            </main-hot>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            bannerCarousel: () => import('@/components/bannerCarousel'),
            mainFilter: () => import("@/components/cosmetics/mainFilter"),
            mainHot: () => import("@/components/imageMall/mainHot"),
            goodsItem: () => import("@/components/imageMall/goodsItem")
        },
    }
</script>

<style scoped>
.page-cosmetics{
    --color: #fca29a;
    background-color: #fff;
    margin-bottom: 50px;
}
.cosmetics-to_equipment{
    display: block;
    text-align: center;
    margin: 50px auto;
}
.cosmetics-to_equipment,
.cosmetics-to_equipment > i{
    font-size: 24px;
    color: #303133;
}
</style>